<template>
    <div class="view">
        <div class="pa24">
            <div class="d-flex">
                <div>
                    <el-radio style="margin-right: 0;" v-model="orderStatusValue" label="all" @change="searchFun"
                        border>
                        全部订单
                    </el-radio>
                    <el-radio style="margin-right: 0;" v-model="orderStatusValue" :label="id" @change="searchFun"
                        v-for="(name, id) in orderStatus" :key="id" border>
                        {{ name }}
                    </el-radio>
                    <el-input class="w200 ml10 mb10 mr10" placeholder="请输入订单编号" v-model="orderCode" />
                    <el-button type="primary" @click="searchFun">搜索</el-button>
                </div>
            </div>
            <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
                <template v-slot:table>
                    <el-table-column prop="ordersNo" align="center" label="订单编号" width="200" />
                    <el-table-column prop="coverImg" align="center" label="商品图片" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-image v-if="scope.row.coverImg" style="width: 100px; height: 100px;margin-bottom:-10px"
                                :src="scope.row.coverImg">
                            </el-image>
                            <p v-else style="width: 100px; height: 100px;line-height: 100px;">
                                暂无图片
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goodsName" align="center" label="商品" show-overflow-tooltip />
                    <el-table-column prop="receiveName" align="center" label="购买用户" show-overflow-tooltip />
                    <el-table-column prop="receivePhone" align="center" label="联系方式" show-overflow-tooltip />
                    <el-table-column prop="receivePhone" align="center" label="收货地址" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div v-if="scope.row.locationAddress=='自提' || scope.row.detailedAddress=='自提'">自提</div>
                            <div v-else>{{ scope.row.locationAddress + scope.row.detailedAddress }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orderPrice" align="center" label="订单金额" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.orderPrice / 100 }}元
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" align="center" label="状态" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-tag>{{ orderStatus[scope.row.orderState] }}</el-tag>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
        </div>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { selectOrdersInfoPc, } from "@/api/association";
export default {
    name: "activityOrder",
    components: {
        commonTable
    },
    data() {
        return {
            //列表配置
            orderStatus: {
                1: "待付款", 2: "待发货", 3: "待收货", 4: "已完成", 5: "交易取消", 6: "退款成功"
            },
            orderStatusValue: "all",
            orderCode: "",
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            tableData: [],
            associationId: 0,
        };
    },
    filters: {

    },
    created() {
        this.associationId = JSON.parse(sessionStorage.getItem("checkedAssociation")).associationId;
        this.getList();
    },
    computed: {
        watchAssociation() {
            return this.$store.state.checkedAssociation
        }
    },
    watch: {
        watchAssociation(row) {
            this.associationId = row.associationId;
            this.currentPage = 1;
            this.afterSalesCurrentPage = 1;
            this.getList();
        }
    },
    methods: {
        /**@method  */
        watchRefundScale() {
            this.showMoney = (this.refundData.payMoney * (this.refundData.refundScale / 100)).toFixed(2)
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                associationId: this.associationId,
                isPage: 1
            };
            if (this.orderStatusValue != 'all') {
                params.orderState = this.orderStatusValue
            }
            if (this.orderCode) {
                params.ordersNo = this.orderCode
            }
            try {
                this.loading = true;
                let res = await selectOrdersInfoPc(params);
                this.loading = false;

                const { data } = res;
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>