<template>
  <div class="view pa24">
      <div>
          <el-tabs v-model="webType">
              <el-tab-pane label="活动订单" name="activityOrder"></el-tab-pane>
              <el-tab-pane label="进驻订单" name="enterOrder"></el-tab-pane>
              <el-tab-pane label="商品订单" name="productOrder"></el-tab-pane>
          </el-tabs>
      </div>
      <div v-if="webType==='activityOrder'">
          <activity-order/>
      </div>
      <div v-if="webType==='enterOrder'">
          <enter-order/>
      </div>
      <div v-if="webType==='productOrder'">
          <product-order/>
      </div>
      
  </div>
</template>

<script>
import activityOrder from "./pages/activityOrder";
import enterOrder from "./pages/enterOrder";
import productOrder from "./pages/productOrder";
export default {
  name: "orderList",
  components: {
    activityOrder,
    productOrder,
    enterOrder
  },
  data() {
      return {
          webType:"activityOrder",
      };
  },
};
</script>

<style lang="scss" scoped>
.switchInput {
  display: flex;

  .selectType {
      width: 150px;
  }
}

.operation-left {
  margin-bottom: 20px;

  .el-button {
      width: 95px;
      height: 32px;
      background: #51cbcd;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;

      &:active {
          border-color: #51cbcd;
      }
  }
}
</style>